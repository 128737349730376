<template>
  <section>
    <section>
      <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->
      <div class="row">
        <div class="col-md-10">
          <h2 class="titulo-links-escolha">Presenças</h2>
        </div>
        <div class="col-md-2"></div>
      </div>
      <div class="row">
        <div
          v-if="
            funcaoDoUsuario == 1 || funcaoDoUsuario == 2 || funcaoDoUsuario == 7
          "
          :class="'col-md-12'"
          style="margin-top: 20px"
        >
          <div class="row" v-if="acesso.visualizar">
            <div :class="'col-md-3'">
              <div class="pm-card">
                <div class="col-md-12" style="margin-bottom: 20px">
                  <label for="escola"> Selecione uma escola</label>
                  <select
                    class="p-inputtext p-component"
                    v-model="escola_id"
                    @change="limparCampos()"
                    style="
                      appearance: revert !important;
                      width: 100% !important;
                    "
                  >
                    <option value="" disabled selected>
                      -- Escolha uma Escola --
                    </option>
                    <option v-for="n in escolas" :key="n" :value="n.id">
                      {{ n.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div :class="'col-md-3'" v-if="escola_id">
              <div class="pm-card">
                <div class="col-md-12" style="margin-bottom: 20px">
                  <label for="escola" value="" disabled selected>
                    Selecione um segmento</label
                  >
                  <select
                    class="p-inputtext p-component"
                    v-model="segmentoInfo"
                    style="
                      appearance: revert !important;
                      width: 100% !important;
                    "
                    @change="listaSerie(segmentoInfo)"
                  >
                    <option value="" disabled selected>
                      -- Escolha um Segmento --
                    </option>
                    <option v-for="s in segmentoALL" :key="s" :value="s.id">
                      {{ s.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div :class="'col-md-3'" v-if="segmentoInfo">
              <div class="pm-card">
                <div class="col-md-12" style="margin-bottom: 20px">
                  <label for="escola" value="" disabled selected>
                    Selecione um período</label
                  >
                  <select
                    class="p-inputtext p-component"
                    v-model="serieInfo"
                    style="
                      appearance: revert !important;
                      width: 100% !important;
                    "
                    @change="listaTurmas()"
                  >
                    <option value="" disabled selected>
                      -- Escolha um Período --
                    </option>
                    <option v-for="a in serieALL" :key="a" :value="a.id">
                      {{ a.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div :class="'col-md-3'" v-if="serieInfo">
              <div class="pm-card">
                <div class="col-md-12" style="margin-bottom: 20px">
                  <label for="escola" value="" disabled selected>
                    Selecione uma turma</label
                  >
                  <select
                    class="p-inputtext p-component"
                    v-model="turma_id"
                    @change="
                      (this.contar_render = 0),
                      (this.ensino_basico = 0),
                      (this.disciplina_id = 0),
                      (this.arr_disciplina_id = []),
                      (this.tabelaDias = []),
                      turma_disciplinas(turma_id),
                      listarAlunosdaTurma(),
                      listaDisciplina();
                      buscarPresencasDisciplina();
                      buscarProfessores(); /*buscarTurma(escola_id,'')*/
                    "
                    style="
                      appearance: revert !important;
                      width: 100% !important;
                    "
                  >
                    <option value="" disabled selected>
                      -- Escolha uma Turma --
                    </option>
                    <option v-for="n in turmaALL" :key="n" :value="n.id">
                      {{ n.nome }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-3" v-if="turma_id != null && mesAtual != 1">
              <div class="pm-card">
                <pm-Button
                  :disabled="situacaoAno == 0" 
                  class="p-button-primary"
                  @click="openModalAutorizacao()"
                  label="Liberar mês anterior"
                />
                &nbsp;
                <va-popover
                  placement="right"
                  color="primary"
                  title="Informação do módulo atual"
                  message="Caso algum professor não tenha lançado as informações de presenças para algum mês anterior, é possível autorizá-lo para fazer!"
                >
                  <i
                    class="pi pi-question-circle"
                    style="font-size: 1.5rem"
                  ></i>
                </va-popover>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            (funcaoDoUsuario == 1 || funcaoDoUsuario == 2) && carregandoPagina
          "
          class="col-md-12"
          style="text-align: center; padding-top: 255px"
        >
          <pm-ProgressSpinner />
        </div>

        <div
          class="col-md-12"
          v-if="!carregandoPagina && acesso.visualizar && turma_id"
        >
          <section>
            <div class="pm-card">
              <div class="row">
                <div class="col-md-12">
                  <h2
                    class="titulo-links-escolha"
                    style="margin-top: 0px !important"
                  >
                    Alunos(as)
                  </h2>
                </div>
              </div>

              <div class="col-md-12" v-if="turma_id">
                <div class="row" v-if="!ensino_basico">
                  <div
                    class="col-md-3"
                    v-if="
                      this.tipo_segmento == 2 ||
                        (this.tipo_segmento == 3 && selectSerie.tipo == 2) ||
                        (selectSerie.possui_mais_de_um_professor &&
                          !ensino_basico)
                    "
                  >
                    <label>Selecione uma Disciplina:</label>
                    <select
                      class="p-inputtext p-component"
                      v-model="disciplina_id"
                      @change="buscarPresencasDisciplina()"
                      style="
                        appearance: revert !important;
                        width: 100% !important;
                      "
                    >
                      <!--<option  v-if="mes == 0">Selecione um mês</option>-->
                      <option :value="n.id" v-for="n in disciplina" :key="n.id">
                        {{ n.nome }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-12" style="margin-top: 25px">
                    <selectCalendario2
                      v-if="
                        ((this.tipo_segmento != 1 && this.tipo_segmento != 0) ||
                          selectSerie.possui_mais_de_um_professor) &&
                          disciplina_id != 0
                      "
                      @exibirTabela="exibirTabela"
                      @test="montarCalendarioProfessor"
                      :mes_nome="this.mes_exib_selec"
                      :mes="this.mes_selec"
                      :tabelaDias="tabelaDias"
                      :mesNumero="mes_numeral"
                      :diaSelecionado="this.info.dia"
                      @dialogAction="dialogAction"
                    />
                    <selectCalendario2
                      v-if="
                        (this.tipo_segmento == 1 || this.tipo_segmento == 0) &&
                          !selectSerie.possui_mais_de_um_professor &&
                          disciplina_id != 0
                      "
                      @exibirTabela="exibirTabela"
                      @test="montarCalendarioProfessor"
                      :mes_nome="this.mes_exib_selec"
                      :mes="this.mes_selec"
                      :tabelaDias="tabelaDias"
                      :mesNumero="mes_numeral"
                      :diaSelecionado="this.info.dia"
                      @dialogAction="dialogAction"
                    />
                  </div>
                  <div class="col-md-12" style="margin-top: 10px"></div>
                </div>

                <div class="row" v-else>
                  <div class="col-md-3">
                    <label>Selecione uma Disciplina:</label>
                    <select
                      class="p-inputtext p-component"
                      v-model="arr_disciplina_id"
                      @change="
                        disciplinaFantasia(this.arr_disciplina_id),
                        buscarPresencasDisciplina()
                      "
                      style="
                        appearance: revert !important;
                        width: 100% !important;
                      "
                    >
                      <option
                        :value="{
                          id: n.disciplina_id,
                          disc_fantasia_id: n.disc_fantasia_id,
                        }"
                        v-for="n in disciplinas_fantasia"
                        :key="n.id"
                      >
                        {{ n.nome_disciplina }}
                      </option>
                    </select>
                  </div>

                  <div
                    class="col-md-12"
                    v-if="tabelaDias.length > 0"
                    style="margin-top: 25px"
                  >
                    <selectCalendario2
                      @exibirTabela="exibirTabela"
                      @test="montarCalendarioProfessor"
                      :mes_nome="this.mes_exib_selec"
                      :mes="this.mes_selec"
                      :tabelaDias="tabelaDias"
                      :mesNumero="mes_numeral"
                      :diaSelecionado="this.info.dia"
                      @dialogAction="dialogAction"
                    />
                  </div>
                  <div class="col-md-12" style="margin-top: 10px"></div>
                </div>
              </div>

              <div v-if="presencasAlunosView">
                <div v-if="abrirComponete && turma_id && disciplina_id != 0">
                  <CalendarioVisualizar
                    @test="montarCalendarioProfessor"
                    @abrirConteudo="abrirConteudo"
                    @abrirTodosOsConteudo="abrirTodosOsConteudo"
                    :acesso="acesso"
                    :presencasAlunosView="presencasAlunosView"
                    :tabelaDias="tabelaDias"
                    :mes_nome="this.mes_exib_selec"
                    :mes_sel="this.mes_selec"
                    :mes="this.mes_exib_selec"
                    :disciplina_id="disciplina_id"
                    :disciplinas_fantasia_id="disciplinas_fantasia_id"
                    :turma_id = "turma_id"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <pm-Dialog
        header="Enviar presença"
        v-model:visible="displayMaximizable"
        :style="{ width: '100vw' }"
        :maximizable="true"
        :modal="true"
      >
        <div class="row">
          <div class="row col-lg-12" v-if="this.info.dataSelecionada != ''" >
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-12" v-if="info.dataSelecionada">
                  <p>
                    Disciplina:
                    <b>{{ disciplina_nome }}</b>
                  </p>
                  <h2 class="text-01-presenca">
                    Data selecionada: <b>{{ info.dataSelecionada }}</b>
                  </h2>
                </div>
                <div
                  class="field col-12 md:col-3 lg:col-3" style="display: inline-flex;"
                  v-if="presenca_infinita == 1"
                >
                  <label style="width: 100%;">Quantidade de presenças</label>
                  <select
                    class="p-inputtext p-component"
                    v-model="qdt_presenca"
                    style="appearance: revert !important; width: 27% !important"
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              </div>
              <div class="p-fluid formgrid grid">
                <div class="row">
                  <div
                    class="row"
                    v-if="selectable && info.dia"
                    style="margin-top: 5px"
                  >
                    <!--<h4 class="title-presenca-table">Alunos da Turma</h4>-->
                    <table-prof-presenca01
                      @marcarPresenca="marcarPresenca"
                      :alunos="alunos"
                      :acesso="acesso"
                      :st_segmento="this.segmentoInfo"
                      :etapaDeEnvioDePresenca="etapaDeEnvioDePresenca"
                    />
                  </div>

                  <div
                    class="row"
                    v-if="this.selectable && info.dia"
                    style="width: 100%; position: fixed; bottom: 0; right: 28px; display: grid; justify-content: end;"
                  >
                    <va-inner-loading
                      class="py-3"
                      style="width: 100%"
                      :loading="isLoading"
                      v-if="acesso.cadastrar && this.alunos.length"
                    >

                      <button
                        :disabled="situacaoAno == 0"
                        class="btn btn-primary"
                        @click="presencaEnviar()"
                        v-if="conteudoObrigatorio == 1"
                      >
                        Enviar Presença
                      </button>
                      <button
                        class="btn btn-primary"
                        disabled
                        v-else
                      >
                        Enviar Presença
                      </button>
                    </va-inner-loading>
                  </div>

                  <div
                    v-if="
                      editarOk &&
                        (this.tipo_segmento == 1 || this.serie_tipo == 1)
                    "
                  >
                    <editarFundamental1
                      @marcarPresenca="marcarPresenca"
                      :alunos="alunos"
                      @EditarPresencaDaTurma="EditarPresencaDaTurma"
                      @fecharEditarFundamentalUm="fecharEditarFundamentalUm"
                    />
                  </div>

                  <div
                    v-if="
                      editarOk &&
                        (this.tipo_segmento == 2 || this.serie_tipo == 2)
                    "
                  >
                    <editarFundamental2
                      @marcarPresencaFundamentalDois="
                        marcarPresencaFundamentalDois
                      "
                      :alunos="alunos"
                      @EditarPresencaDaTurma="EditarPresencaDaTurma"
                      @fecharEditarFundamentalUm="fecharEditarFundamentalUm"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <h3>Conteúdo Aplicado</h3>
              <modalConteudoAplicado
                edicao="0"
                :dados="{
                  disc_fantasia_id: this.arr_disciplina_id.disc_fantasia_id,
                  disciplina_id: this.disciplina_id,
                  data: this.info.dataSelecionada,
                  turma_id: this.turma_id,
                }"
                :mes_c="this.mes_c"
                :completo="0"
                @changeStatusModal="changeStatusModal"
                @saveAppliedContent ="saveAppliedContent"
                @conteudoObg ="conteudoObg"
              />
            </div>
          </div>
        </div>
        <template #footer> </template>
      </pm-Dialog>

      <pm-Dialog
        v-if="funcaoDoUsuario == 1 || funcaoDoUsuario == 2"
        header="Liberar autorização"
        v-model:visible="displayMaximizableEdit2"
        :maximizable="true"
      >
        <pm-Button
          title="Editar"
          type="button"
          @click="cadastrarInfo()"
          label="Cadastrar info"
          icon="pi pi-file"
          class="p-button-primary p-button-sm btn-color;table_style"
          style="margin-left: 5px; margin-bottom: 5px"
        >
        </pm-Button>
        <div class="col-md-12"></div>
        <pm-DataTable
          :value="listaAutorizacaoALL"
          dataKey="id"
          :rowHover="true"
          filterDisplay="menu"
          responsiveLayout="scroll"
        >
          <pm-Column
            field="data"
            header="Nome"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <div>
                {{ data.nome }}
              </div>
            </template>
          </pm-Column>
          <pm-Column
            field="data"
            header="Mês que foi liberado"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <div>
                {{ data.mes }}
              </div>
            </template>
          </pm-Column>
          <pm-Column
            field="data"
            header="Quantidade de dias liberados"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <div>{{ data.qdt_dias }} dias</div>
            </template>
          </pm-Column>
          <pm-Column
            field="data"
            header="Ações"
            style="font-size: 14px; vertical-align: middle !important"
          >
            <template #body="{ data }">
              <pm-Button
                title="Editar"
                type="button"
                @click="editarAutori(data)"
                label="Editar info"
                icon="pi pi-pencil"
                class="p-button-primary p-button-sm btn-color;table_style"
                style="margin-left: 5px"
              >
              </pm-Button>
              <pm-Button
                title="Apagar"
                type="button"
                @click="apagarAutorizacao(data)"
                label="Apagar"
                icon="pi pi-times-circle"
                class="p-button-danger p-button-sm btn-color;table_style"
                style="margin-left: 5px"
              >
              </pm-Button>
            </template>
          </pm-Column>
        </pm-DataTable>
      </pm-Dialog>

      <pm-Dialog
        v-if="funcaoDoUsuario == 1 || funcaoDoUsuario == 2"
        header="Liberar mês anterior para o professor"
        v-model:visible="displayMaximizable1"
        :style="{ width: '50vw' }"
        :maximizable="true"
        :modal="true"
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-5">
            <label for="servidor">Servidor:</label>
            <pm-Dropdown
              v-model="infoAutorizacao.servidor_id"
              :options="professoresALL"
              optionLabel="nome_todo"
              placeholder="Selecione o servidor"
            />
          </div>

          <div class="field col-12 md:col-3">
            <label for="servidor">Mês liberado:</label>
            <pm-Dropdown
              v-model="infoAutorizacao.mes"
              :options="mesesALL"
              optionLabel="nome"
              placeholder="Selecione o mês liberado"
            />
          </div>

          <div class="field col-12 md:col-4">
            <label for="servidor"
            >Selecione o tempo de liberação(entre 2 e 7 dias)</label
            >
            <pm-InputNumber
              id="minmax-buttons"
              v-model="infoAutorizacao.qdt_dias"
              mode="decimal"
              showButtons
              :min="2"
              :max="7"
            />
          </div>
        </div>
        <template #footer>
          <pm-Button
            v-if="
              tipoDeAcao == 1 &&
                infoAutorizacao.servidor_id != '' &&
                infoAutorizacao.mes != undefined
            "
            label="Cadastrar informações"
            icon="pi pi-check"
            @click="salvarAutorizacao"
            autofocus
          />
          <pm-Button
            v-if="
              tipoDeAcao == 2 &&
                infoAutorizacao.servidor_id != '' &&
                infoAutorizacao.mes != undefined
            "
            label="Editar informações"
            icon="pi pi-check"
            @click="editarAutorizacao"
            autofocus
          />
        </template>
      </pm-Dialog>

      <pm-Dialog
        header="Conteúdo Aplicado" v-model:visible="displayModalConteudo" :style="{width: '100vw'}" :maximizable="true" :modal="true">
        <modalConteudoAplicado
          edicao="1"
          :dados="this.dados"
          :mes_c="this.mes_c"
          :completo="this.completo"
          @changeStatusModal="changeStatusModal"
          @saveAppliedContent ="saveAppliedContent"
          @conteudoObg ="conteudoObg"
        />
      </pm-Dialog>
    </section>
  </section>
</template>

<script>
import axios from "axios";
import { defineComponent } from "vue";
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { Presenca } from "@/class/presenca";
import { DisciplinaEscolar } from "@/class/disciplina";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Verificar } from "@/class/verificar.js";
import { Servidores } from "@/class/servidores";
import { SeriesEscolar } from "@/class/serie.js";
import { LiberacaoDiario } from "@/class/LiberacaoDiario.js";
import selectCalendario2 from "@/components/presenca/selectCalendario2.vue";
import CalendarioVisualizar from "@/components/presenca/calendarioVisualizar.vue";

import tableProfPresenca01 from "@/components/presenca/tableProfPresenca01.vue";
import editarFundamental1 from "@/components/presenca/editarFundamental1.vue";
import editarFundamental2 from "@/components/presenca/editarFundamental2.vue";

import modalConteudoAplicado from "@/components/conteudo_aplicado/modal-conteudo-aplicado.vue";
import { PlanejamentoPedagogico } from "@/class/planejamentopedagogico";

import { Configuracao } from "@/class/configuracao";

export default defineComponent({
  components: {
    selectCalendario2,
    CalendarioVisualizar,
    tableProfPresenca01,
    editarFundamental1,
    editarFundamental2,
    modalConteudoAplicado
  },
  data() {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "ano", label: "Ano", sortable: true },
      { key: "aluno", label: "Ação" },
    ];
    return {
      mesesALL: [
        { id: "fevereiro", nome: "Fevereiro" },
        { id: "marco", nome: "Março" },
        { id: "abril", nome: "Abril" },
        { id: "maio", nome: "Maio" },
        { id: "junho", nome: "Junho" },
        { id: "julho", nome: "Julho" },
        { id: "agosto", nome: "Agosto" },
        { id: "setembro", nome: "Setembro" },
        { id: "outubro", nome: "Outubro" },
        { id: "novembro", nome: "Novembro" },
        { id: "dezembro", nome: "Dezembro" },
      ],
      meses: [
        { numero: "01", palavra: "janeiro" },
        { numero: "02", palavra: "fevereiro" },
        { numero: "03", palavra: "marco" },
        { numero: "04", palavra: "abril" },
        { numero: "05", palavra: "maio" },
        { numero: "06", palavra: "junho" },
        { numero: "07", palavra: "julho" },
        { numero: "08", palavra: "agosto" },
        { numero: "09", palavra: "setembro" },
        { numero: "10", palavra: "outubro" },
        { numero: "11", palavra: "novembro" },
        { numero: "12", palavra: "dezembro" },
      ],
      tipoDeAcao: -1,
      qdt_presenca: 1,
      infoAutorizacao: {
        turma_id: -1,
        modulo: "presencas",
        servidor_id: -1,
        qdt_dias: 2,
      },
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),

      col1: 12,
      col2: 12,
      col3: 3,
      col4: 12,
      col5: 2,

      abrirComponete: false,
      tipo_segmento: 0,
      serie_tipo: 0,
      tabelaDias: [],
      tabelaDiasApenasAnosLetivoColunas: [],
      info: [],
      arr_disciplina_id: [],
      dataSelecionaFormatoEUA: "",
      presencasAlunosView: [],
      mes_selec: "",
      mes_exib_selec: "",
      todosOsAlunos: [],
      displayMaximizable: false,
      displayMaximizable1: false,
      displayMaximizableEdit2: false,
      selectable: true,
      isLoading: false,
      mesLoading: false,
      porcentagemParaSerAprovado: 70,

      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Presenças" }],
      filter: "",
      columns,
      escola: null,
      escola_nome: null,
      escolas: [],
      turma: null,
      turmas: [],
      aluno: 0,
      alunos: [],
      alunosPresente: [],
      presenca: 0,
      theturma: null,
      professor_id: null,
      escola_id: null,
      segmentoInfo: null,
      serieInfo: null,
      professorInfo: "",
      mes: 0,
      disciplina: [],
      presencas: [],
      segmentoALL: [],
      serieALL: [],
      turmaALL: [],
      alunoALL: [],
      disciplina_id: 0,
      disciplina_nome: null,
      expandedRows: [],
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },
      professoresALL: [],
      turma_id: null,
      mesAtual: "",
      listaAutorizacaoALL: [],
      carregandoPagina: false,
      tabelaPresencasColunas: [],

      data_final_matricula: null,
      data_inicial_matricula: null,
      funcaoDoUsuario: 0,
      selectSerie: "",
      presenca_infinita: 0,
      ensino_basico: 0,
      disciplinas_fantasia: [],
      disciplinas_fantasia_id: 0,
      contar_render: 0,
      displayModalConteudo:false,
      dados:[],
      mes_c:"",
      completo:0,
      conteudoAplicado:[],
      conteudoObrigatorio:0,
      nVazio:[],
      nV:0,
      situacaoAno: 0
    };
  },

  async beforeMount() {
    const gerou_ata = parseInt(sessionStorage.getItem("gerou_ata"));
    if (gerou_ata == 1) {
      sessionStorage.setItem("gerou_ata", 0);
      window.location.reload();
    }
    
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.funcaoDoUsuario = parseInt(sessionStorage.getItem("funcaoDoUsuario"));
    const data = await Calendario.calendarioAtual();

    if (data.data["error"] != "Não encontrado!") {
      const dt = await Configuracao.obtemOne(data.data[0].id);
      this.data_final_matricula = dt.data.data_final_matricula;
      this.data_inicial_matricula = dt.data.data_inicial_matricula;
    }

    await this.listarEscolas();
    const acesso = await Verificar.AcessoLocal(10);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },

  methods: {
    conteudoObg(valor){
      this.conteudoObrigatorio = valor
    },
    changeStatusModal(val){
      if(val == 1){
       this.displayModalConteudo = false
      }else{
        this.displayModalConteudo = true
      }
    },
    async abrirTodosOsConteudo(mes,it){
      console.log(it)
      this.mes_c = "";
      this.mes_c = mes;
      this.completo = 1;
      this.dados = it;
      this.displayModalConteudo = true;
    },
    async abrirConteudo(mes,it){
      console.log(it)
      this.mes_c = "";
      this.mes_c = mes;
      this.completo = 0;
      this.dados = [];
      this.dados = it;
      this.displayModalConteudo = true;
    },
    async getQdtPresenca() {
      const data = await axios.get(
        "/config/sistema/presenca/" + this.segmentoInfo + "/segmento"
      );
      this.presenca_infinita = data.data.presenca_infinita;
    },
    async editarAutorizacao() {
      try {
        this.infoAutorizacao.servidor_id = this.infoAutorizacao.servidor_id.id;
        this.infoAutorizacao.mes_liberado = this.infoAutorizacao.mes.id;

        const data = await LiberacaoDiario.editarLiberacao(this.infoAutorizacao);
        this.$vaToast.init({
          message: "Salvo com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 2500,
          fullWidth: false,
        });
        this.limparCamposAutorizacao();
        this.listarAuto();
        this.displayMaximizable1 = false;
      } catch (e) {
        if (e.response.data.validacao) {
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: "fa-star-o",
                position: "bottom-left",
                duration: 3500,
                fullWidth: false,
                color: "danger",
              });
            }, 500);
          });
        } else {
          let a = [];
          a = e.response.data.erro;

          setTimeout(() => {
            this.$vaToast.init({
              message: a,
              iconClass: "fa-star-o",
              position: "bottom-left",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
        }
        this.limparCamposAutorizacao();
        this.displayMaximizable1 = false;
      }
    },
    cadastrarInfo() {
      //cadastar
      this.limparCamposAutorizacao();
      this.tipoDeAcao = 1;
      this.displayMaximizable1 = !this.displayMaximizable1;
    },
    async apagarAutorizacao(data) {
      try {
        if (
          window.confirm(
            "Você realmente deseja apagar a autorização selecionada?"
          )
        ) {
          try {
            const data1 = await LiberacaoDiario.deletarAutorizacao(data.id);
            this.$vaToast.init({
              message: "Removido com sucesso!",
              iconClass: "fa-star-o",
              position: "top-right",
              color: "success",
              duration: 2500,
              fullWidth: false,
            });
            this.listarAuto();
          } catch (e) {
            const a = e.response.data.erro;
            setTimeout(() => {
              this.$vaToast.init({
                message: a,
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                fullWidth: false,
                color: "danger",
              });
            }, 500);
          }
        }
      } catch (e) {}
    },
    async editarAutori(data) {
      this.tipoDeAcao = 2;
      this.infoAutorizacao.id = data.id;
      this.infoAutorizacao.turma_id = data.turma_id;
      this.infoAutorizacao.mes = this.mesesALL.find(
        (element) => element.id == data.mes_liberado
      );

      this.infoAutorizacao.servidor_id = this.professoresALL.find(
        (element) => element.id == data.servidor_id
      );

      this.infoAutorizacao.periodo_semestre = "";
      this.displayMaximizable1 = !this.displayMaximizable1;
    },
    limparCamposAutorizacao() {
      this.infoAutorizacao.turma_id = "";
      this.infoAutorizacao.servidor_id = "";
      this.infoAutorizacao.periodo_semestre = "";
    },
    async buscarProfessores() {
      const data = await Turma.buscarProfessoresDaTurma(this.turma_id);
      this.professoresALL = [];
      for (const el of data.data) {
        el.nome_todo = el.nome + " " + el.sobrenome;
        this.professoresALL.push(el);
      }
    },
    async salvarAutorizacao() {
      this.infoAutorizacao.turma_id = this.turma_id;
      this.infoAutorizacao.servidor_id = this.infoAutorizacao.servidor_id.id;
      this.infoAutorizacao.periodo_semestre = "";
      this.infoAutorizacao.mes_liberado = this.infoAutorizacao.mes.id;
      try {
        const data = await LiberacaoDiario.CadastrarDiario(this.infoAutorizacao);
        this.$vaToast.init({
          message: "Salvo com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 2500,
          fullWidth: false,
        });
        this.limparCamposAutorizacao();
        this.listarAuto();
        this.displayMaximizable1 = false;
      } catch (e) {
        if (e.response.data.validacao) {
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: "fa-star-o",
                position: "bottom-left",
                duration: 3500,
                fullWidth: false,
                color: "danger",
              });
            }, 500);
          });
        } else {
          let a = [];
          a = e.response.data.erro;

          setTimeout(() => {
            this.$vaToast.init({
              message: a,
              iconClass: "fa-star-o",
              position: "bottom-left",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
        }
        this.limparCamposAutorizacao();
        this.displayMaximizable1 = false;
      }
    },
    async listarAuto() {
      const data = await LiberacaoDiario.visualizarLiberacao("presencas");
      this.listaAutorizacaoALL = [];
      for (const el of data.data) {
        el.nome = el.servidor.nome + " " + el.servidor.sobrenome;
        const found = this.mesesALL.find(
          (element) => element.id == el.mes_liberado
        );
        if (found != undefined) {el.mes = found.nome;}
        this.listaAutorizacaoALL.push(el);
      }
    },
    async openModalAutorizacao() {
      this.listarAuto();
      this.displayMaximizableEdit2 = !this.displayMaximizableEdit2;
    },
    voltar() {
      this.$router.push({ name: "turmas" });
    },
    fecharEditarFundamentalUm() {
      this.editarOk = !this.editarOk;
    },
    limparCampos() {
      this.segmentoALL = [];
      this.serieALL = [];
      this.turmaALL = [];

      this.turma_id = null;
      this.segmentoInfo = null;
      this.serieInfo = null;

      this.buscarSegmentoALL();
    },
    async listarEscolas() {
      const esc = await Escola.obtemTodos();

      for (const el of esc.data) {
        const novo = {
          id_: el.id,
          id: el.id,
          logradouro: el.logradouro,
          bairro: el.bairro,
          cep: el.cep,
          cidade: el.cidade,
          cnpj: el.cnpj,
          email: el.email,
          estado: el.estado,
          nome: el.nome,
          numero: el.numero,
          items: [
            {
              label: "Editar Escola",
              icon: "pi pi-pencil",
              command: () => {
                this.editarEscola(el.id);
              },
            },
            {
              label: "Informações da Escola",
              icon: "pi pi-search-plus",
              command: () => {
                this.editarInfo(el.id);
              },
            },
          ],
        };
        this.escolas.push(novo);
      }
    },
    async buscarTurma(id, nome) {
      try {
        this.presenca = 0;
        this.alunos = [];
        this.escola = id;
        const anoSelect = sessionStorage.getItem("anoSelecionado");
        const data = await Escola.obtemInfoEscola(id, anoSelect);
        this.turmas = data.data;
      } catch (e) {}
    },
    async turma_disciplinas(turma) {
      this.turma = turma;
      this.presenca = 0;
      this.alunos = [];
      const data = await Turma.obtemAlunosTurma(this.turma);
      this.alunos = data.data;

      if (this.alunos.length == 0) {
        this.$vaToast.init({
          message: "Nenhum aluno encontrado!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 3000,
          color: "warning",
          fullWidth: false,
        });
      }
    },

    async exibirTabela(dia, semana, mes, mesLetra) {
      this.editarOk = false;
      this.info.dia = dia;
      this.info.mes = mesLetra;
      this.info.semana = semana;
      // if (dia.length == 1)
      // dia = '0' + dia;
      this.info.dataSelecionada =
        String(dia).padStart(2, "0") +
        "/" +
        mes.padStart(2, "0") +
        "/" +
        this.info.ano;
      this.dataSelecionaFormatoEUA =
        this.info.ano + "-" + mes + "-" + String(dia).padStart(2, "0");
    },

    async presenca_tudo_turma(id) {
      const data = await Turma.obtemPresencaTudo(id);
      this.alunos = data.data;
    },
    async mostrar_presenca(aluno) {
      this.presenca = 1;
      this.aluno = aluno;
      const data = await Turma.obtemUm(this.turma);
      this.theturma = data.data;
      this.mes = 0;
      (this.disciplina_id = null), (this.disciplina = []);
      this.presencas = [];
      this.alunos = [];
      this.listaDisciplina();
    },
    async listarTudo() {
      try {
        const token = sessionStorage.getItem("token");
        const whoiam = await LoginInfo.WhoIam(token);

        if (whoiam.data.funcao != 5) {
          this.$router.push({ name: "login" });
        }
      } catch (e) {}
    },

    dialogAction() {
      this.alunos = [];
      for (const el of this.todosOsAlunos) {
        let dataDeEntradaNaTurma = el.created_at.split("T");
        dataDeEntradaNaTurma = dataDeEntradaNaTurma[0].substring(0, 10);
        //Verificar se a data que o aluno entrou na turma é menor ou igual a data selecionada. E, se o aluno foi transferido, se a data que foi transferido já chegou. Se chegou nao pode mais lancar presenca
        if (
          dataDeEntradaNaTurma <= this.dataSelecionaFormatoEUA &&
          (el.saiu_da_turma_data == null ||
            this.dataSelecionaFormatoEUA < el.saiu_da_turma_data)
        ) {
          this.alunos.push(el);
        }
      }
      if (!this.alunos.length) {
        this.$vaToast.init({
          message: "A turma não tinha alunos na data selecionada!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "warning",
          duration: 3000,
          fullWidth: false,
        });
      } 
      else if (!this.alunos[0]['turmaAtivada']) {
        this.$vaToast.init({
          message: "A turma não está ativa. Não é possível lançar presenças!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "warning",
          duration: 3000,
          fullWidth: false,
        });
      }
      else {
        this.displayMaximizable = true;
      }
      // this.displayMaximizable = true;
    },

    async turmaAlunos() {
      this.todosOsAlunos = [];
      const data = await Turma.obtemAlunosTurma(this.turma_id);
      this.todosOsAlunos = data.data;
    },

    async buscarPresencasDisciplina() {
      const date = new Date();
      const today = date.getDate();
      const currentMonth = date.getMonth() + 1;
      this.mesAtual = currentMonth;
      if (currentMonth == 1) {
        await this.montarCalendarioProfessor("janeiro", "Janeiro");
      }
      if (currentMonth == 2) {
        await this.montarCalendarioProfessor("fevereiro", "Fevereiro");
      }
      if (currentMonth == 3) {
        await this.montarCalendarioProfessor("marco", "Março");
      }
      if (currentMonth == 4) {
        await this.montarCalendarioProfessor("abril", "Abril");
      }
      if (currentMonth == 5) {
        await this.montarCalendarioProfessor("maio", "Maio");
      }
      if (currentMonth == 6) {
        await this.montarCalendarioProfessor("junho", "Junho");
      }
      if (currentMonth == 7) {
        await this.montarCalendarioProfessor("julho", "Julho");
      }
      if (currentMonth == 8) {
        await this.montarCalendarioProfessor("agosto", "Agosto");
      }
      if (currentMonth == 9) {
        await this.montarCalendarioProfessor("setembro", "Setembro");
      }
      if (currentMonth == 10) {
        await this.montarCalendarioProfessor("outubro", "Outubro");
      }
      if (currentMonth == 11) {
        await this.montarCalendarioProfessor("novembro", "Novembro");
      }
      if (currentMonth == 12) {
        await this.montarCalendarioProfessor("dezembro", "Dezembro");
      }
    },
    //Receber todos os dia que possuem presencas
    receberTodosOsDiasQueExistemPresencas() {
      const myPresencasDias = new Set();
      for (let i = 0; i < this.presencasAlunosView.length; i++) {
        for (let j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (
            !myPresencasDias.has(this.presencasAlunosView[i].presencas[j].dia)
          ) {
            myPresencasDias.add(this.presencasAlunosView[i].presencas[j].dia);
          }
        }
      }
      return Array.from(myPresencasDias).sort((a, b) => a - b);
    },
    //Buscar todas as presencas que existem em um dia X
    todasAsPresencasQueExistemNoDia(dia) {
      const mySet = new Set();
      const todasAsPresencasNoDia = [];
      for (let i = 0; i < this.presencasAlunosView.length; i++) {
        for (let j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (
            !mySet.has(this.presencasAlunosView[i].presencas[j].data) &&
            dia == this.presencasAlunosView[i].presencas[j].dia
          ) {
            todasAsPresencasNoDia.push(
              this.presencasAlunosView[i].presencas[j]
            );
            mySet.add(this.presencasAlunosView[i].presencas[j].data);
            //Para add dias para criar as colunas da tabela
            this.tabelaPresencasColunas.push(dia);
          }
        }
      }
      return todasAsPresencasNoDia.sort(function (a, b) {
        return a.presenca_id - b.presenca_id;
      });
    },
    criarTabelaDePresencasParaCadaAluno(mapOfValues) {
      //Para exibir os dados na tabela de presenca
      for (var i = 0; i < this.presencasAlunosView.length; i++) {
        this.presencasAlunosView[i].presencas1 = [];
      }
      for (const dia of mapOfValues) {
        var presencasDoAluno = this.todasAsPresencasQueExistemNoDia(dia);
        for (var i = 0; i < this.presencasAlunosView.length; i++) {
          for (var j = 0; j < presencasDoAluno.length; j++) {
            //Verificar se o aluno i possue a presenca j
            const found = this.presencasAlunosView[i].presencas.find(
              (element) => element.data == presencasDoAluno[j].data
            );

            if (found != undefined) {
              this.presencasAlunosView[i].presencas1.push(found);
            } else {
              console.log(" dawdaw: "+ presencasDoAluno[j])
              const aluno = {
                data: presencasDoAluno[j].data,
                disciplina_id: presencasDoAluno[j].disciplina_id,
                disc_fantasia_id: presencasDoAluno[j].disc_fantasia_id,
                escola_id: presencasDoAluno[j].escola_id,
                segmento_id: presencasDoAluno[j].segmento_id,
                serie_id: presencasDoAluno[j].serie_id,
                professor_id: presencasDoAluno[j].professor_id,
                turma_id: presencasDoAluno[j].turma_id,
              };
              this.presencasAlunosView[i].presencas1.push(aluno);
            }
          }
        }
      }
    },

    async montarCalendarioProfessor(mes, mesExibir) {
      await this.professorPrincipal(this.turma_id);

      if (
        (this.ensino_basico == 1 && this.contar_render == 0) ||
        (this.selectSerie.possui_mais_de_um_professor == 1 &&
          this.contar_render == 0)
      ) {
        this.contar_render = this.contar_render + 1;
        return false;
      }

      this.tabelaDias = [];
      this.tabelaPresencasColunas = [];
      this.presencasAlunosView = [];
      this.tabelaDiasApenasAnosLetivoColunas = [];

      this.carregandoPagina = true;
      this.mes_selec = mes;
      this.mes_exib_selec = mesExibir;
      this.mesLoading = true;

      this.info.dia = "";
      this.info.semana = "";
      this.info.dataSelecionada = "";
      const anoSelect = sessionStorage.getItem("anoSelecionado");
      this.info.ano = anoSelect;

      async function chamarCalendarioProf(
        mes,
        anoSelect,
        disciplina_id,
        turma_id,
        segmentoInfo,
        serieInfo,
        disciplinas_fantasia_id = 0
      ) {
        const novo = {
          disciplina_id: disciplina_id,
          mes: mes,
          turma_id: turma_id,
          segmento_id: segmentoInfo,
          serie: serieInfo,
          ano: anoSelect,
        };
        let data;
        if (disciplinas_fantasia_id < 1) {
          data = await Presenca.presencaMontarCalendarioProfessor(novo);
        } else {
          data = await Presenca.presencaMontarCalendarioProfessorDiscFantasia(
            novo,
            disciplinas_fantasia_id
          );
        }
        return data.data;
      }

      this.turmaAlunos();

      async function tipoSegmentoDisciplina(
        tipo_segmento,
        serie_tipo,
        selectSerie,
        funcaoDoUsuario,
        disciplina_id,
        ensino_basico,
        disciplinas_fantasia,
        disciplinas_fantasia_id,
        disciplina
      ) {
        const arr = {
          disciplina_id: 0,
          disciplina_nome: " ",
        };

        if (!ensino_basico) {
          if (
            (tipo_segmento == 1 || (tipo_segmento == 3 && serie_tipo == 1)) &&
            !selectSerie
          ) {
            if (
              funcaoDoUsuario != 1 &&
              funcaoDoUsuario != 2 &&
              funcaoDoUsuario != 7
            ) {
              arr.disciplina_id = 1;
            }

            arr.disciplina_id = 1;
            arr.disciplina_nome = "Todas as Disciplinas";
          } else if (tipo_segmento == 0) {
            arr.disciplina_id = 2;
            arr.disciplina_nome = "Educação Infantil";
          } else {
            arr.disciplina_id = disciplina_id;
            const busca_disciplina = disciplina.find(
              (element) => element.id == disciplina_id
            );
            if (busca_disciplina) {
              arr.disciplina_nome = busca_disciplina.nome;
            }
          }
        } else {
          arr.disciplina_id = disciplina_id;
          if (disciplina_id > 1) {
            const busca_disciplina = disciplinas_fantasia.find(
              (element) => element.disciplina_id == disciplina_id
            );
            if (busca_disciplina) {
              arr.disciplina_nome = busca_disciplina.nome_disciplina;
            }
          } else {
            const busca_disciplina = disciplinas_fantasia.find(
              (element) => element.disc_fantasia_id == disciplinas_fantasia_id
            );
            if (busca_disciplina) {
              arr.disciplina_nome = busca_disciplina.nome_disciplina;
            } else {
              arr.disciplina_nome = "Todas as Disciplinas";
            }
          }
        }

        return arr;
      }

      tipoSegmentoDisciplina(
        this.tipo_segmento,
        this.serie_tipo,
        this.selectSerie.possui_mais_de_um_professor,
        this.funcaoDoUsuario,
        this.disciplina_id,
        this.ensino_basico,
        this.disciplinas_fantasia,
        this.disciplinas_fantasia_id,
        this.disciplina
      )
        .then((res) => {
          this.disciplina_id = res.disciplina_id;
          this.disciplina_nome = res.disciplina_nome;
        })
        .catch((e) => {
          console.log(e);
        });

      //receber mês números
      const found = this.meses.find((element) => element.palavra == mes);
      this.mes_numeral = found.numero;

      await chamarCalendarioProf(
        mes,
        anoSelect,
        this.disciplina_id,
        this.turma_id,
        this.segmentoInfo,
        this.serieInfo,
        this.disciplinas_fantasia_id
      )
        .then((res) => {
          this.tabelaDias = res;
        })
        .catch((e) => {
          if (e.response.data.erro) {
            setTimeout(() => {
              this.$vaToast.init({
                message: e.response.data.erro,
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                fullWidth: false,
                color: "danger",
              });
            }, 50);
          } else {
            setTimeout(() => {
              this.$vaToast.init({
                message: e.response.data.message,
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                fullWidth: false,
                color: "danger",
              });
            }, 500);
          }
        })
        .finally(() => {
          //Apenas com dia letivo
          for (let i = 0; i < this.tabelaDias.length; i++) {
            if (this.tabelaDias[i].letivo == "0") {continue;}
            if (this.tabelaDias[i].dia.length == 1)
              {this.tabelaDias[i].dia = "0" + this.tabelaDias[i].dia;}
            this.tabelaDiasApenasAnosLetivoColunas.push(this.tabelaDias[i]);
          }
          this.mesLoading = false;
        });

      async function presencaMensal(
        disciplina_id,
        mes,
        turma_id,
        anoSelect,
        data_inicial_matricula,
        data_final_matricula,
        disciplinas_fantasia_id
      ) {
        //presencas visu
        const res = {
          disciplina_id: disciplina_id,
          mes: mes,
          turma_id: turma_id,
          ano: anoSelect,
        };
        const data1 = await Presenca.mesesVisu(res, disciplinas_fantasia_id);
        const arr = [];

        for (const el of data1.data) {
          const info = {
            id: el.aluno.id,
            nome: el.aluno.nome + " " + el.aluno.sobrenome,
            evento_saida: el.evento_saida,
            presencas: [],
            cntPresencas: 0,
            data_entrada_turma: el.data_entrada_na_turma,
            data_entrada_turma_br: el.data_entrada_na_turma_br,
            entrou_no_prazo_certo: el.entrou_no_prazo_certo,
            status: el.status,
            transferido: el.transferido,
            saiu_da_turma_data: el.saiu_da_turma_data,
            entrou_na_turma_por_transferencia:
              el.entrou_na_turma_por_transferencia,
            saiu_da_turma_data_formato_br: el.saiu_da_turma_data_formato_br,
            entradaESaidaDaTurma: el.entradaESaidaDaTurma,
            historico_saida_entrada_turma: el.historico_saida_entrada_turma,
            turma_id: el.turma_id,
          };

          for (const el1 of el.presenca) {
            const novo = {
              presenca_id: el1.id,
              aluno_id: el.aluno.id,
              data: el1.data,
              disciplina_id: el1.disciplina_id,
              escola_id: el1.escola_id,
              id_presenca: el1.id,
              justificacao_conteudo: el1.justificacao_conteudo,
              justificada_falta: el1.justificada_falta,
              presenca: el1.presenca,
              professor_id: el1.professor_id,
              segmento_id: el1.segmento_id,
              serie_id: el1.serie_id,
              turma_id: el1.turma_id,
              disc_fantasia_id: el1.disc_fantasia_id,
              dia: "",
            };
            //contar presencas
            info.cntPresencas += el1.presenca;
            //receber dia
            let receberDia = novo.data.split("-");
            receberDia = receberDia[2].split(" ");
            novo.dia = receberDia[0];
            info.presencas.push(novo);
          }
          arr.push(info);
        }
        return arr;
      }

      await presencaMensal(
        this.disciplina_id,
        mes,
        this.turma_id,
        anoSelect,
        this.data_inicial_matricula,
        this.data_final_matricula,
        this.disciplinas_fantasia_id
      )
        .then((res) => {
          this.presencasAlunosView = res;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.tabelaPresencasColunas = [];
          this.criarTabelaDePresencasParaCadaAluno(
            this.receberTodosOsDiasQueExistemPresencas()
          );
          this.presencasAlunosView[0].tableColuna = this.tabelaPresencasColunas;
        });

      this.carregandoPagina = false;
      this.abrirComponete = true;
      //abrir modal
      return true;
    },

    marcarPresenca(n, item) {
      item.presente = n;
    },

    marcarPresencaFundamentalDois(chamada, n, item) {
      if (chamada == 1) {item.presenca_1 = n;}
      else {item.presenca_2 = n;}
    },

    async saveAppliedContent(conteudo, envio){

      for (let i = 0; i < conteudo.length; i++) {
        if(conteudo[i].texto.length > 0 ){
          this.nVazio[i] = 1;
        }else{
          this.nVazio[i] = 0;
        }
      }

      let sum = 0;
      for (let i = 0; i < conteudo.length; i++) {
        sum+=this.nVazio[i];
      }

      if(sum == 0){
        sessionStorage.setItem("conteudoObrigatorio", 0);
        this.conteudoObg(0);
      }

      if(envio === 1){
        // salva o conteudo na Api
        for (let i = 0; i < conteudo.length; i++) {
          await PlanejamentoPedagogico.addPlanejamentoProf(conteudo[i]);
        }
      }else{
        // guarda em variavel
        this.conteudoAplicado = [];
        this.conteudoAplicado = conteudo
      }
    },

    async presencaEnviar() {
      try {
        this.abrirComponete = false;
        this.carregandoPagina = true;
        this.alunosPresente = [];
        //array dos alunos
        for (let i = 0; i < this.alunos.length; i++) {
          if (this.alunos[i].presente == undefined) {
            alert("Adicione as informações de presença para todos os alunos!");
            return false;
          }
          const novo = {
            aluno_id: this.alunos[i].id,
            presenca: this.alunos[i].presente,
            justificada_falta: 0,
            justificacao_conteudo: "",
          };
          this.alunosPresente.push(novo);
        }

        //2022-05-26 10:38:11
        //Receber a hora atual
        const now = new Date();
        let horas = String(now.getHours());
        let minutos = String(now.getMinutes());
        let segundos = String(now.getSeconds());
        if (horas.length == 1) {horas = "0" + horas;}
        if (minutos.length == 1) {minutos = "0" + minutos;}
        if (segundos.length == 1) {segundos = "0" + segundos;}

        const horaAtual = horas + ":" + minutos + ":" + segundos;

        //Data selecionada pelo usuário
        const dataFormatoUSA = this.info.dataSelecionada.split("/");
        const dataAtual =
          dataFormatoUSA[2] + "-" + dataFormatoUSA[1] + "-" + dataFormatoUSA[0];
        const dataEHora = dataAtual + " " + horaAtual;

        async function criarPresenca(
          alunosPresente,
          segmentoInfo,
          escola_id,
          turma_id,
          serieInfo,
          disciplina_id,
          dataEHora,
          qdt_presenca,
          disciplinas_fantasia_id
        ) {
          const info = {
            segmento_id: segmentoInfo,
            escola_id: escola_id,
            turma_id: turma_id,
            serie_id: serieInfo,
            professor_id: 0,
            disciplina_id: disciplina_id,
            data: dataEHora,
            qdt_presenca: qdt_presenca,
          };

          let data;
          if (disciplinas_fantasia_id < 1) {
            data = await Presenca.criarPresencaDosAlunos(alunosPresente, info);
          } else {
            info.disc_fantasia_id = disciplinas_fantasia_id;
            data = await Presenca.criarPresencaDosAlunos(alunosPresente, info);
          }
          return data;
        }

        this.displayMaximizable = false;

        await criarPresenca(
          this.alunosPresente,
          this.segmentoInfo,
          this.escola_id,
          this.turma_id,
          this.serieInfo,
          this.disciplina_id,
          dataEHora,
          this.qdt_presenca,
          this.disciplinas_fantasia_id
        )
          .then((res) => {
            this.$vaToast.init({
              message: "Presença enviada com sucesso!",
              iconClass: "fa-star-o",
              position: "top-right",
              color: "success",
              duration: 3000,
              fullWidth: false,
            });
          })
          .catch((e) => {
            this.$vaToast.init({
              message: e.response.data.erro,
              iconClass: "fa-star-o",
              position: "top-right",
              color: "danger",
              duration: 3000,
              fullWidth: false,
            });
          })
          .finally(() => {
            this.abrirComponete = true;
            this.qdt_presenca = 1;
            this.turmaAlunos();
            this.montarCalendarioProfessor(this.mes_selec, this.mes_exib_selec);
            this.isLoading = false;
          });

        this.saveAppliedContent(this.conteudoAplicado, 1)

        this.presencasAlunosView = [];
      } catch (e) {
        this.$vaToast.init({
          message: "Error ao enviar presença!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "danger",
          duration: 3000,
          fullWidth: false,
        });
        this.isLoading = false;
      }
    },

    async EditarPresencaDaTurma(alunos) {
      try {
        this.alunosPresente = [];
        for (let i = 0; i < alunos.length; i++) {
          if (alunos[i].presente != undefined) {
            const novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome: alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presente,
              presenca_2: null,
              ano: this.info.ano,
              mes: this.info.mes,
              dia: this.info.dia,
            };
            this.alunosPresente.push(novo);
          } else {
            const novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome: alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presenca_1,
              presenca_2: alunos[i].presenca_2,
              ano: this.info.ano,
              mes: this.info.mes,
              dia: this.info.dia,
            };
            this.alunosPresente.push(novo);
          }
        }

        //Gerar presencas

        const pf = await Servidores.buscarServidorIdLogin(this.st_professor_id);
        const data = await Presenca.EditarPresencaDaTurma(
          this.alunosPresente,
          pf.data.id,
          this.disciplinaSelecionadaPeloProf.turma_disciplina_id,
          this.infoParaEnviar.turma_id,
          this.qdt_presenca
        );

        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "success",
          duration: 3000,
          fullWidth: false,
        });
        this.editarOk = false;
      } catch (e) {
        this.$vaToast.init({
          message: "Problema ao editar!",
          iconClass: "fa-star-o",
          position: "top-right",
          color: "danger",
          duration: 3000,
          fullWidth: false,
        });
      }
    },

    async buscarSegmentoALL() {
      //const data = await SegmentoEscolar.obtemTodos();
      const data = await SegmentoEscolar.obtemSegmentosEscola(this.escola_id);
      this.segmentoALL = [];
      for (const el of data.data) {
        //if (el.id == 5)
        //continue;
        this.segmentoALL.push(el);
      }
    },

    async listaSerie(id) {
      this.turma_id = null;
      this.serieInfo = null;

      const dataseg = await SegmentoEscolar.obtemUm(id);
      this.tipo_segmento = dataseg.data.tipo;

      //const data = await SegmentoEscolar.listarSegmentosPorSerie(id);
      const data = await SegmentoEscolar.listarSegmentosPorSerieExistente(this.escola_id,id);

      this.serieALL = data.data;
      return this.seriesALL;
    },

    async listaTurmas() {
      const play = {
        escola: this.escola_id,
        serie: this.serieInfo,
        segmento: this.segmentoInfo,
      };

      const data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;

      const dat = await SeriesEscolar.obtemUm(this.serieInfo);
      this.selectSerie = dat.data;
      if (this.tipo_segmento != 2) {
        this.serie_tipo = dat.data.tipo;
      } else {
        this.serie_tipo = 0;
      }

      await this.getQdtPresenca();
    },

    async listarAlunosdaTurma() {
      const data = await Turma.alunoEmUmaTurma(this.turma);
      this.alunoALL = data.data;
    },

    async listaDisciplina() {
      const data = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma);
      this.disciplina = [];
      for (const el of data.data) {
        if (el.na_turma == 1) {
          const novo = {
            id: el.id,
            nome: el.nome,
            status: el.status,
          };
          this.disciplina.push(novo);
        }
      }
    },

    async init() {
      try {
        const calendario = await Calendario.calendarioAtual();

        if (
          this.segmento_tipo == 1 ||
          (this.segmento_tipo == 3 && this.serie_tipo == 1)
        ) {
          this.porcentagemParaSerAprovado = calendario.data.porcent_fund1_falta;
        } else {
          this.porcentagemParaSerAprovado =
            calendario.data.porcent_outros_falta;
        }
      } catch (e) {}
    },

    async professorPrincipal(turma_id) {
      const playload = {
        turma_id: turma_id,
        professor_id: 0,
      };
      const res = await Servidores.ProfessorPrincipalShow(playload);
      if (res.data.length > 0) {
        this.ensino_basico = 1;
        this.disciplinas_fantasia = res.data;
      } else {
        this.ensino_basico = 0;
      }
    },

    disciplinaFantasia(n) {
      this.disciplinas_fantasia_id = n.disc_fantasia_id;
      this.disciplina_id = n.id;
    },
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
.th_prf {
  background-color: #0067a7 !important;
  color: #fff;
  text-align: left;
  padding: 15px !important;
  padding-left: 25px !important;
  border: 1px solid;
}
.th_prf1 {
  background-color: #2e92d1 !important;
  color: #fff;
  text-align: left;
  padding: 15px !important;
  padding-left: 25px !important;
  border: 1px solid;
}
.tbody_prf {
  border-top: 0px solid currentColor !important;
}
</style>
